import React, {useContext, useEffect, useState} from "react";
import {Person} from "../Views/BirthdayView/BirthdayView";
import {Context} from "../ContextProvider/Context";
import {Config} from "../Views/SettingsView/SettingsView";


export interface ApiAuthentication {
    apiKey: string
    expiresAt: string | null
    id: number
    isAuthenticated: string
    name: string
    qrCode: string
    tierID: number
    tier: AccountTier
    whitelistedIP: string
    profilePictureURL: string
}

export interface AccountTier {
    maxPersons: number | null
    price: number
    tierID: number
    tierName: string
    transcriptionLimit: number | null
}

export interface Contact {
    id: number
    contactID: string
    name: string
    number: string
    profilePictureURL: string,
}

interface ServiceRequestType {
    updatedAt: string,
    getPersonByID: (id: string) => Promise<{ message: Person[], type: string }>,
    updateCongratulateOnBirthday: (id: number, congratulateOnBirthday: boolean) => Promise<{
        message: boolean,
        type: string
    }>
    updateTranscribeHidden: (id: number, transcribeHidden: boolean) => Promise<{ message: boolean, type: string }>
    updateTranslateTranscriptions: (id: number, transcribeHidden: boolean) => Promise<{
        message: boolean,
        type: string
    }>
    getAllBirthday: () => Promise<{ message: Person[], type: string }>
    getAllContacts: () => Promise<{ message: Contact[], type: string }>
    getTierList: () => Promise<{ message: AccountTier[], type: string }>
    upgradeTier: (tierID: number) => Promise<{ message: { id: string, link: string }, type: string }>
    login: (email: string, password: string) => Promise<{ message: ApiAuthentication, type: string }>
    register: (name: string, email: string, password: string) => Promise<{ message: string, type: string }>
    getSettings: () => Promise<{ message: Config[], type: string }>
    getTranscriptionUsage: () => Promise<{ message: { totalUsage: number }, type: string }>
    getApiKeyInformation: () => Promise<{ message: ApiAuthentication, type: string }>
    setSetting: (config: Config) => Promise<{ message: string, type: string }>
    getFact: () => Promise<{ message: string, type: string }>
    getUpdatedAt: () => {}
}

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE'

export enum BackendAnswerType {
    foundPerson = 'foundPerson',
    updatedPerson = 'updatedPerson',
    unknownError = 'unknownError',
    apiKeyCredentials = 'apiKeyCredentials'
}

export const Api = React.createContext<ServiceRequestType | undefined>(undefined)

export const RequestProvider = ({children}: any) => {

    const [apiKey] = useState(localStorage.getItem('apiKey') ?? null)
    const [updatedAt, setUpdatedAt] = useState("")
    const context = useContext(Context)
    const {setContentLoading} = context!


    useEffect(() => {
        getUpdatedAt().then()
    }, [])

    const header = (method: RequestType) => ({
        method,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey ?? '',
            'x-api-dashboard-request': 'x'
        }
    })
    const url = (path: string) => {
        let url = path;
        if (url.startsWith('/')) {
            url = url.replace(/^\//, '');
        }
        return process.env.REACT_APP_BACKEND_ENV === 'development' ? `${process.env.REACT_APP_API_ADDRESS}/${url}` : `https:/${process.env.REACT_APP_API_ADDRESS}/${url}`
    }
    const request = async (method: RequestType, path: string, body?: Record<string, any>) => {
        try {
            const response = await fetch(
                url(path),
                method === 'GET' ? {
                    ...header(method)
                } : {
                    ...header(method),
                    body: JSON.stringify(body)
                }
            )

            return response.json();
        } catch (e) {
            console.error('Error:', e)
        }
    }
    const getPersonByID = async (id: string) => {
        const response = await request('POST', 'api/person/find', {id})
        if (response.message.length > 0) {
            return response as { message: Person[], type: BackendAnswerType }
        } else {
            return {message: response.message, type: 'unknownError'}
        }
    }
    const getUpdatedAt = async () => {
        setContentLoading(true)
        const response = await request('GET', 'api/healthCheck')

        if (response.type === 'apiKeyCredentials') {
            setUpdatedAt(response.message.updatedAt)
            setTimeout(() => setContentLoading(false), 600)
        } else {
            console.log("something went wrong:", response.message)
        }
    }
    const updateCongratulateOnBirthday = async (id: number, congratulateOnBirthday: boolean) => {
        setContentLoading(true)
        try {
            const response = await request('PUT', 'api/person/update/congratulateOnBirthday', {
                id,
                congratulateOnBirthday
            })
            return response as { message: boolean, type: BackendAnswerType }
        } catch (e) {
            return {message: false, type: 'unknownError'}
        }
    }

    const getAllBirthday = async (): Promise<{ message: Person[], type: string }> => {
        setContentLoading(true)
        try {
            const response = await request('GET', 'api/birthday/all')
            return {message: response.message as Person[], type: response.type}
        } catch (e) {
            console.log(e)
            return {message: [] as Person[], type: 'unknownError'}
        }
    }
    const getAllContacts = async (): Promise<{ message: Contact[], type: string }> => {
        setContentLoading(true)
        try {
            const response = await request('GET', 'api/contact/getAll')
            return {message: response.message as Contact[], type: response.type}
        } catch (e) {
            console.log(e)
            return {message: [] as Contact[], type: 'unknownError'}
        }
    }
    const getSettings = async (): Promise<{ message: Config[], type: string }> => {
        setContentLoading(true)
        try {
            const response = await request('GET', 'api/settings/get')
            return {message: response.message as Config[], type: response.type}
        } catch (e) {
            console.log(e)
            return {message: [] as Config[], type: 'unknownError'}
        }
    }
    const setSetting = async (config: Config): Promise<{ message: string, type: string }> => {
        setContentLoading(true)
        try {
            return await request('PUT', 'api/settings/set', config)
        } catch (e) {
            console.log(e)
            setContentLoading(false)
            return {message: "500", type: 'unknownError'}
        }
    }
    const getFact = async (): Promise<{ message: string, type: string }> => {
        setContentLoading(true)
        try {
            return await request('GET', 'api/thirdParty/fact')
        } catch (e) {
            console.log(e)
            setContentLoading(false)
            return {message: "500", type: 'unknownError'}
        }
    }
    const updateTranscribeHidden = async (id: number, transcribeHidden: boolean) => {
        setContentLoading(true)
        try {
            const response = await request('PUT', 'api/person/update/transcribeHidden', {id, transcribeHidden})
            return response as { message: boolean, type: BackendAnswerType }
        } catch (e) {
            return {message: false, type: 'unknownError'}
        }
    }

    const updateTranslateTranscriptions = async (id: number, translateTranscriptions: boolean) => {
        setContentLoading(true)
        try {
            const response = await request('PUT', 'api/person/update/translateTranscriptions', {
                id,
                translateTranscriptions
            })
            return response as { message: boolean, type: BackendAnswerType }
        } catch (e) {
            return {message: false, type: 'unknownError'}
        }
    }

    const login = async (email: string, password: string) => {
        setContentLoading(true)
        try {
            const body = {
                email,
                password
            }
            const apiKeyInformation: {
                type: string,
                message: ApiAuthentication
            } = await request('POST', 'auth/login', body)
            return apiKeyInformation
        } catch (e) {
            console.log(e)
            return {message: {} as ApiAuthentication, type: 'unknownError'}
        }
    }
    const register = async (name: string, email: string, password: string) => {
        setContentLoading(true)
        try {
            const body = {
                name,
                email,
                password
            }
            const registered: {
                type: string,
                message: string
            } = await request('POST', 'auth/register', body)
            return registered
        } catch (e) {
            console.log(e)
            return {message: "", type: 'unknownError'}
        }
    }
    const getApiKeyInformation = async () => {
        setContentLoading(true)
        try {
            const apiKeyInformation: { type: string, message: ApiAuthentication } = await request('GET', 'api/')
            const tier: { type: string, message: AccountTier } = await request('GET', 'api/getAccountTier')
            apiKeyInformation.message.tier = tier.message
            return apiKeyInformation
        } catch (e) {
            console.log(e)
            return {message: {} as ApiAuthentication, type: 'unknownError'}
        }
    }
    const getTierList = async () => {
        setContentLoading(true)
        try {
            const tierList: { type: string, message: AccountTier[] } = await request('GET', '/getTierList')
            return tierList
        } catch (e) {
            console.log(e)
            return {message: {} as AccountTier[], type: 'unknownError'}
        }
    }
    const upgradeTier = async (tierID: number) => {
        setContentLoading(true)
        try {
            const tierList: {
                type: string,
                message: { id: string, link: string }
            } = await request('POST', 'api/payment/upgradeTier', {tierID})
            return tierList
        } catch (e) {
            console.log(e)
            return {message: {id: "", link: ""}, type: 'unknownError'}
        }
    }
    const getTranscriptionUsage = async () => {
        setContentLoading(true)
        try {
            const usage: {
                message: { totalUsage: number },
                type: string
            } = await request('GET', 'api/transcription/usage')
            return usage
        } catch (e) {
            console.log(e)
            return {message: {totalUsage: 0}, type: "error"}
        }
    }

    return (
        <Api.Provider value={{
            getPersonByID,
            updateCongratulateOnBirthday,
            updateTranscribeHidden,
            updateTranslateTranscriptions,
            getAllBirthday,
            getAllContacts,
            getSettings,
            setSetting,
            getFact,
            updatedAt,
            login,
            register,
            upgradeTier,
            getTierList,
            getUpdatedAt,
            getTranscriptionUsage,
            getApiKeyInformation
        }}>
            {children}
        </Api.Provider>
    )
}