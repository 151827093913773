import React, {useContext, useEffect, useState} from "react";
import './LandingPage.css'
import {PlusLg, Dot} from "react-bootstrap-icons";
import CButtonGreenFilled from "../../Components/ButtonFilled/CButtonGreenFilled";
import mockup1 from "../../assets/images/mockup/iMockup - iPhone 14.png";
import mockup2 from "../../assets/images/mockup/iMockup - iPhone 14-1.png";
import mockup3 from "../../assets/images/mockup/iMockup - Google Pixel 8 Pro.png";
import mockup4 from "../../assets/images/mockup/iMockup - birthday.png";
import mockup5 from "../../assets/images/mockup/iMockup - deleted.png";
import CButtonFilled from "../../Components/ButtonGreenFilled/CButtonFilled";
import moment from "moment/moment";
import {TierCard} from "../../Components/TierCard/TierCard";
import {AccountTier, Api} from "../../RequestsProvider/RequestProvider";
import {BarLoader} from "react-spinners";
import {Context} from "../../ContextProvider/Context";
import {useNavigate} from "react-router-dom";


export const LandingPage = () => {


    const [tierList, setTierList] = useState<AccountTier[]>()

    const context = useContext(Context)
    const request = useContext(Api)
    const navigate = useNavigate()
    useEffect(() => {
        getTierList().then(tl => {
            setTierList(tl.message)
            setTimeout(() => setContentLoading(false), 1350)
        })
    }, [])

    if (!context || !request) {
        return (<></>)
    }

    const {contentLoading, setContentLoading} = context
    const {getTierList, getApiKeyInformation} = request
    const renderLoading = () => {
        return contentLoading ? <BarLoader
            color={'#25D366'}
            height={2}
            width={"100%"}
            cssOverride={{backgroundColor: "transparent", position: 'absolute'}}
            speedMultiplier={.8}
        /> : null
    }
    return (
        <>
            {renderLoading()}
            <section id={"home"} className="section section-1 container-fluid position-relative">
                <div className="row bg-black px-2 py-1">
                    <div className="row m-1">
                        <div className="col-8 col-lg-6 text-wa_green">
                            <div className="row fs-22">WhatsAppBot</div>
                            <div className="row fs-12 font-regular">mache deine Chats einzigartig!</div>
                        </div>
                        <div className="col-4 col-lg-6 justify-content-end d-flex">
                            <button className={"btn fw-bold my-1"}
                                    onClick={() => navigate("/login", {replace: true})}>Login
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row px-1">
                    <div className="head-navigation">
                        <div className="col-6 text-truncate">
                            <span className={"text-muted font-regular"}>Der erste amerikanische Astronaut im Weltraum war Alan B. Shepard Jr</span>
                        </div>
                        <div className="col-6 justify-content-end d-flex px-2">
                            <CButtonFilled content={"Registrieren"} onClick={() => {
                                navigate("/register", {replace: true})
                            }} icon={<PlusLg/>}/>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row min-vh-100 align-content-center">
                        <div className="col-8 col-md-6">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Dein WhatsApp Bot</h1>
                                    <h6>Bringe dein WhatsApp auf das nächste Level.</h6>
                                </div>
                            </div>
                            <div className="row mt-5 fs-5">
                                <div className="col-12">
                                    <p>Mit dem WhatsApp-Bot wird dein Chat-Alltag einfacher, produktiver und spannender.
                                        Automatisiere Geburtstagsgrüße, lass dir Sprachnachrichten transkribieren,
                                        entdecke gelöschte Nachrichten und bring Abwechslung in Gespräche mit
                                        faszinierenden Fakten.
                                        Alles direkt in WhatsApp - ohne zusätzlichen Aufwand!</p>
                                </div>
                            </div>
                            <div className="row mt-5 d-none d-md-block">
                                <div className="col-12 d-flex justify-content-center">
                                    <CButtonGreenFilled content={"Jetzt kostenlos loslegen"}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-md-6 d-none d-md-block">
                            <div className="row">
                                <div className="col-6"><img loading={"lazy"} className={"img-fluid"} src={mockup1}
                                                            alt="mockUp1"/></div>
                                <div className="col-6"><img loading={"lazy"} className={"img-fluid"} src={mockup2}
                                                            alt="mockUp1"/></div>
                            </div>
                        </div>
                        <div className="row mt-5 d-block d-md-none">
                            <div className="col-12 d-flex justify-content-center">
                                <CButtonGreenFilled content={"Jetzt kostenlos loslegen"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-mock1 d-block d-md-none"></div>
            </section>

            <section id={"transkribieren"} className="section section-2 position-relative overflow-hidden">

                <div className="container p-4 p-md-0">
                    <div className="row min-vh-100 align-content-md-center mt-5 mt-md-0 d-flex justify-content-center">
                        <div className="col-12 col-md-6 order-2 order-md-1 d-none d-md-block">
                            <div className="row d-flex d-md-block justify-content-center">
                                <div className="col-6"><img loading={"lazy"} className={"img-fluid"} src={mockup3}
                                                            alt="mockUp3"/></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2">
                            <div className="row">
                                <div className="col-12 text-center text-md-start">
                                    <h1>Transkriptionen!</h1>
                                    <h6>Verwandle Worte in Text - blitzschnell!</h6>
                                </div>
                            </div>
                            <div className="row mt-5 fs-5">
                                <div className="col-12 text-center text-md-start">
                                    <p>Nie mehr langes Anhören von Sprachnachrichten:
                                        Dein WhatsApp-Bot transkribiert automatisch jede Sprachnachricht und liefert dir
                                        den Inhalt als Text
                                        ideal, wenn du gerade keine Zeit zum Zuhören hast.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img
                    loading="lazy"
                    className="position-absolute top-50 end-0 translate-middle-y section-img d-none d-xl-block"
                    src={mockup1}
                    alt="mockUp1"
                />
                <img
                    loading="lazy"
                    className="position-absolute section-img-mobile d-md-none start-50 translate-middle"
                    src={mockup3}
                    alt="mockUp1"
                />

            </section>

            <section id={"geburtstage"} className="section section-3 position-relative overflow-hidden">
                <div className="container">
                    <div className="row min-vh-100 align-content-md-center">
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-12 mt-5 mt-md-0">
                                    <h1>Geburtstagswünsche</h1>
                                    <h6>Mach Geburtstage unvergesslich!</h6>
                                </div>
                            </div>
                            <div className="row mt-4 mt-md-5 fs-5">
                                <div className="col-11 col-md-12">
                                    <p>Vergiss nie wieder einen Geburtstag! Dein WhatsApp-Bot gratuliert deinen
                                        Kontakten automatisch mit einer einzigartigen Nachricht, die von ChatGPT
                                        speziell für die jeweilige Person generiert wird. So werden deine Grüße
                                        individuell und hinterlassen einen bleibenden Eindruck.</p>
                                </div>
                            </div>
                            <div className="row mt-5 d-none d-md-block">
                                <div className="col-12">
                                    <CButtonGreenFilled content={"Jetzt kostenlos loslegen"}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-none d-md-block">
                            <img loading={"lazy"} className={"img-fluid"} src={mockup4}
                                 alt="mockUp4"/>
                        </div>
                        <div className="col-12 d-flex justify-content-center d-md-none bottom-0 position-absolute mb-5">
                            <CButtonGreenFilled content={"Jetzt kostenlos loslegen"}/>
                        </div>
                    </div>
                </div>
                <img
                    loading="lazy"
                    className="position-absolute section-img-mobile2 d-md-none start-100 translate-middle"
                    src={mockup4}
                    alt="mockUp1"
                />
            </section>

            <section id={"loeschen"} className="section section-4">
                <div className="container text-white">
                    <div className="row min-vh-100 align-content-center">
                        <div className="col-12 col-md-6 order-2 order-md-1 d-flex justify-content-center">
                            <div className="row d-flex justify-content-center justify-content-md-start">
                                <div className="col-8 col-md-6 mb-5 mb-md-0"><img loading={"lazy"}
                                                                                  className={"img-fluid"} src={mockup5}
                                                                                  alt="mockUp5"/></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2">
                            <div className="row text-center text-md-start">
                                <div className="col-12 mt-5 mt-md-0">
                                    <h1>Gelöschte Nachrichten</h1>
                                    <h6>Keine Nachricht bleibt verborgen!</h6>
                                </div>
                            </div>
                            <div className="row mt-5 fs-5">
                                <div className="col-12 text-center text-md-start px-5 px-md-0 mb-5 mb-md-0">
                                    <p>Gelöschte Nachrichten? Kein Problem!
                                        Dein Bot zeigt dir, was dir jemand vorenthalten wollte.
                                        Bleib informiert und lass dich nicht im Dunkeln tappen.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"fakten"} className="section section-5">
                <div className="container">
                    <div className="row min-vh-100 align-content-center">
                        <div className="col-12 col-md-6 order-2 order-md-1 mt-4 mt-md-0">
                            <div className="row pb-4">
                                <div className="col-12 d-flex justify-content-around">
                                    <div className="col-12 col-md-8">
                                        <div className="msg first">
                                            <div className="bubble">
                                                <div className="txt">
                                                    <span className="name">Mike</span>
                                                    <span className="timestamp">{moment().format('h:mm A')}</span>
                                                    <span className="message">Menschen, die rauchen, haben zehnmal so viele Falten wie jemand, der nicht raucht</span>
                                                </div>
                                                <div className="bubble-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-4">
                                <div className="col-12 col-md-8">
                                    <div className="msg first">
                                        <div className="bubble">
                                            <div className="txt">
                                                <span className="name">Arnold</span>
                                                <span className="timestamp">{moment().format('h:mm A')}</span>
                                                <span className="message">Wespen, die sich von Fermenten ernähren, betrinken sich eigentlich und werden ohnmächtig.</span>
                                            </div>
                                            <div className="bubble-arrow"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-around">
                                    <div className="col-12 col-md-8">
                                        <div className="msg first">
                                            <div className="bubble">
                                                <div className="txt">
                                                    <span className="name">Anja</span>
                                                    <span className="timestamp">{moment().format('h:mm A')}</span>
                                                    <span className="message">Finnland hat 187.888 Seen und 179.584 Inseln</span>
                                                </div>
                                                <div className="bubble-arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Fakten Fakten Fakten</h1>
                                    <h6>Wissen auf Knopfdruck</h6>
                                </div>
                            </div>
                            <div className="row mt-5 fs-5">
                                <div className="col-12">
                                    <p>Beeindrucke deine Freunde und Familie:
                                        Wenn dein Chatpartner “Bot Fact” schreibt, liefert dein WhatsApp-Bot sofort eine
                                        spannende Tatsache direkt im Chat. Perfekt, um Gespräche aufzulockern oder dein
                                        Wissen zu teilen! </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={"pricing"} className="section section-3">
                <div className="mt-5 mt-md-0 mb-5 mb-md-0">
                    <div className="row p-0 p-md-4 min-vh-100 align-content-md-center">
                        <div className="col-12">
                            <h1 className="text-center mb-4">Pick the best plan for you</h1>
                        </div>
                        {tierList ? <>
                            <div className="col-12 col-lg-4">
                                <TierCard accountTier={tierList[0]}/>
                            </div>
                            <div className="col-12 col-lg-4">
                                <TierCard accountTier={tierList[2]}/>
                            </div>
                            <div className="col-12 col-lg-4">
                                <TierCard accountTier={tierList[1]}/>
                            </div>
                        </> : null}
                    </div>
                </div>
            </section>
            <section className={"section-4"}>
                <div className="container text-center text-md-start">
                    <div className="row d-flex justify-content-center justify-content-md-start">
                        <div className="col-6 text-wa_green mt-3">
                            <div className="row fs-22 d-flex justify-content-center justify-content-md-start">WhatsAppBot</div>
                            <div className="row fs-12 font-regular d-flex justify-content-center justify-content-md-start">mache deine Chats einzigartig!</div>
                        </div>
                    </div>
                    <div className="row mt-5 mt-md-0">
                        <div className="col-12 col-md-6 d-flex flex-column justify-content-center pb-5">
                            <div className="row">
                                <div className="col-12 p-0"><CButtonGreenFilled
                                    content={"Jetzt kostenlos loslegen"}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="row mb-2 header-footer d-flex justify-content-center justify-content-md-start">PRODUCT</div>
                            <div className="row">
                                <ul className={"list-unstyled mb-5 px-0 text-white"}>
                                    <li className={"mb-2"}><a href="#home"
                                                              className={"text-decoration-underline"}>Home</a></li>
                                    <li className={"mb-2"}><a href="#transkribieren"
                                                              className={"text-decoration-underline"}>Transkribieren</a>
                                    </li>
                                    <li className={"mb-2"}><a href="#geburtstage"
                                                              className={"text-decoration-underline"}>Geburtstage</a>
                                    </li>
                                    <li className={"mb-2"}><a href="#loeschen"
                                                              className={"text-decoration-underline"}>Löschen</a>
                                    </li>
                                    <li className={"mb-2"}><a href="#fakten"
                                                              className={"text-decoration-underline"}>Fakten</a>
                                    </li>
                                    <li className={"mb-2"}><a href="#pricing"
                                                              className={"text-decoration-underline"}>Preise</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="row mb-2 header-footer d-flex justify-content-center justify-content-md-start">LEGAL</div>
                            <div className="row">
                                <ul className={"list-unstyled mb-5 px-0 text-white d-flex flex-row flex-md-column justify-content-center justify-content-md-start"}>
                                    <li className={"mb-2"}><a href=""
                                                              className={"text-decoration-underline"}>Impressum</a>
                                    </li>
                                    <li className={"mb-2 d-md-none px-4"}><a href=""
                                                              className={"text-decoration-underline"}><Dot className={"header-footer mt-1"}/></a>
                                    </li>
                                    <li className={"mb-2"}><a href=""
                                                              className={"text-decoration-underline"}>Datenschutz</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row text-white d-flex justify-content-center">
                        <hr/>
                        <div className="row my-1 mb-3">
                            <div className="col-12 col-md-6"><small>Copyright @WhatsAppBot2024</small></div>
                            <div className="col-6 d-none d-md-block">
                                <div className="col-12 d-flex justify-content-end align-items-center">
                                    <a className={"text-decoration-underline"} href="">Impressum</a>
                                    <Dot className={"header-footer mt-1"}/>
                                    <a className={"text-decoration-underline"} href="">Datenschutz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}