import "./CButtonGreenFilled.css"
import {useNavigate} from "react-router-dom";
const CButtonGreenFilled = (props:{
    icon?:any,
    content:any,
}) => {
    const navigate = useNavigate()
    return (
        <button className={"c-button-green-filled"} onClick={()=>{
            navigate("/register", { replace: true })
        }}><div>{props.icon} {props.content}</div></button>
    )
}

export default CButtonGreenFilled
