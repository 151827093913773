import React, {useContext, useEffect, useState} from "react";
import './RegisterView.css';
import RandomSVGPicture from "../../Components/RandomSVGPicture/RandomSVGPicture";
import {Spinner} from "react-bootstrap";
import {BarLoader} from "react-spinners";
import {Navigate, useNavigate} from "react-router-dom";
import {ArrowLeft} from "react-bootstrap-icons";
import {Context} from "../../ContextProvider/Context";
import {Api} from "../../RequestsProvider/RequestProvider";

export const RegisterView = () => {
    const [errorCode, setErrorCode] = useState<string | null>(null);
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
    const [isRequestingApiKey, setIsRequestingApiKey] = useState<boolean>(false);
    const [registered, setRegistered] = useState<boolean>(false);
    const [processingLogin, setProcessingLogin] = useState<boolean>(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const context = useContext(Context)
    const request = useContext(Api)
    const navigate = useNavigate()
    const verifyAPIKey = async () => {
        if (!isValidEmail()) {
            setErrorCode("Bitte Email angeben.")
            return
        }
        if (password !== password2) {
            setErrorCode("Passwörter müssen übereinstimmen.")
            return
        }
        setIsRequestingApiKey(true);
        setErrorCode(null); // Reset error code
        setTimeout(async () => {
            try {
                const dat = await register(name, email, password)

                console.log(dat)
                if(dat.type === 'userAdded'){
                    setTimeout(() => {
                        setRegistered(true)
                        setTimeout(async () => {
                            setProcessingLogin(true)
                            const logIn = await login(email, password)
                            if(logIn.message.isAuthenticated === 'true'){
                                setTimeout(async () => {
                                    localStorage.setItem('apiKey', logIn.message.apiKey)
                                    localStorage.setItem('apiKeyName', logIn.message.name)
                                    localStorage.setItem('expiresAt', logIn.message.expiresAt?? '')
                                    window.location.reload()
                                }, 500)
                            }
                        },800)
                    }, 700)
                }else{
                    setTimeout(()=>{
                        setContentLoading(false)
                        setIsRequestingApiKey(false)
                        setErrorCode(dat.message)
                    },400)
                }
                /* if (response.status === 403) {
                     setIsValid(false);
                     setIsRequestingApiKey(false);
                 } else if (response.status === 200) {
                     setIsValid(true);
                     setTimeout(() => {
                         localStorage.setItem('apiKey', apiKey)
                         localStorage.setItem('apiKeyName', dat.message.name)
                         localStorage.setItem('expiresAt', dat.message.expiresAt)
                         window.location.reload()
                     }, 600);
                 } else {
                     setErrorCode(dat.message);
                     setIsRequestingApiKey(false)
                 }*/
            } catch (error) {
                console.error(error);
                setErrorCode("An error occurred while verifying the API key.");
                setIsRequestingApiKey(false);
            }
        }, 500)
    };

    const isValidEmail = () => {
        const regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        return regex.test(email)
    }

    useEffect(() => {
        setContentLoading(true)
        setTimeout(() => setContentLoading(false), 1200)
    }, []);
    useEffect(() => {
        if (isValid !== undefined) {
            const timeout = setTimeout(() => {
                setIsValid(undefined);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [isValid]);

    useEffect(() => {
        if (errorCode) {
            setTimeout(() => {
                setErrorCode(null)
            }, 1400)
        }
    }, [errorCode]);

    if (!context || !request) {
        return (<></>)
    }
    const {contentLoading, setContentLoading} = context
    const {register, login} = request


    return (
        <div>
            <div className="bg-black px-2 py-1">
                <div className="row m-1">
                    <div className="col-6 text-wa_green">
                        <div className="row fs-22">WhatsAppBot</div>
                        <div className="row fs-12 font-regular">mache deine Chats einzigartig!</div>
                    </div>
                </div>
            </div>
            <div>
                <div className="head-navigation py-2 p-0">
                    <div className="col-8">
    <span className={"text-muted font-regular"}><button onClick={() => navigate("/", {replace: true})}
                                                        className={"btn border"}>
    <ArrowLeft className={"pb-1"}/> Startseite
    </button></span>
                    </div>
                </div>
            </div>
            <div>
            </div>
            {contentLoading ? <div className={"login mt-3"}>
                <h1>
                    <RandomSVGPicture width={100} height={100} rotate={true}/><br/>
                    <Spinner size={'sm'}/>
                </h1>
                {registered ? <div className="text-center">
                    <small>Registrierung erfolgreich</small>
                </div> : null}
                {processingLogin ? <div className="text-center">
                    <small>Einloggen ...</small>
                </div> : null}
            </div> : <div className="login fadeIn">
                <h1>
                    <RandomSVGPicture width={100} height={100} rotate={true}/><br/>
                    Registrieren
                </h1>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    verifyAPIKey().then();
                }}>
                    <input
                        disabled={isRequestingApiKey}
                        type="text"
                        placeholder="Dein Name"
                        onChange={(e) => setName(e.target.value)}
                    /><input
                    className={"mt-2"}
                    disabled={isRequestingApiKey}
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                    <input
                        className={"mt-2"}
                        disabled={isRequestingApiKey}
                        type="password"
                        placeholder="Passwort"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        className={"mt-2"}
                        disabled={isRequestingApiKey}
                        type="password"
                        placeholder="Passwort wiederholen"
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    {isRequestingApiKey ? <BarLoader
                        color={'#4ceb9c'}
                        height={3}
                        width={"100%"}
                        cssOverride={{backgroundColor: "transparent"}}
                        speedMultiplier={.8}
                    /> : null}
                    <div className="errorCode mt-2">{errorCode}</div>
                    <button type="submit" className="btn btn-primary btn-block btn-large text-dark">
                        {isRequestingApiKey ? <Spinner size={'sm'}/> : 'Jetzt Registrieren'}
                    </button>
                    <br/>
                </form>
            </div>}
        </div>
    );
};
