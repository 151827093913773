import "./CButtonFilled.css"
import {MouseEventHandler} from "react";
const CButtonFilled = (props:{
    icon?:any,
    content:any,
    onClick:MouseEventHandler,
}) => {

    return (
        <button className={"c-button-filled"} onClick={props.onClick}><div>{props.icon} {props.content}</div></button>
    )
}

export default CButtonFilled
