import './SettingsView.css'
import React, {useContext, useEffect, useState} from "react"
import HeadNavigation from "../../Components/HeadNavigation/HeadNavigation"
import randomSVGPicture from "../../Components/RandomSVGPicture/RandomSVGPicture"
import moment from "moment"
import {description, SettingKey} from "./SettingKeyDescription"
import {Context} from "../../ContextProvider/Context"
import QuarterHourTimer from "../../Components/QuarterHourTimer/QuarterHourTimer"
import {Gear, Infinity} from "react-bootstrap-icons"
import CSwitch from "../../Components/Switch/CSwitch"
import {Api, ApiAuthentication} from "../../RequestsProvider/RequestProvider"
import Modal from "../../Components/Modal/Modal"
import {toast} from "react-toastify"
import PersonalizedBotMessageModal from "./ModalContent/PersonalizedBotMessageModal/PersonalizedBotMessageModal"

interface Props {
    apiKey: string
}

export interface Config {
    id: number,
    apiKeyID: number,
    setting_key: SettingKey,
    setting_value: boolean | string,
    created_at: string,
    updated_at: string
}

export const SettingsView = ({apiKey}: Props) => {
    const [showModal, setShowModal] = useState(false)
    const [showPersonalizedBotMessageModal, setShowPersonalizedBotMessageModal] = useState(false)
    const [settings, setSettings] = useState<Config[]>()
    const [personalizedBotAnswer, setPersonalizedBotAnswer] = useState<Config>()
    const [transcribeWithBotMessage, setTranscribeWithBotMessage] = useState<Config>()
    const [transcribeAudios, setTranscribeAudios] = useState<Config>()
    const [transcribeGroupMessages, setTranscribeGroupMessages] = useState<Config>()
    const [enableDailyBirthdayChecker, setEnableDailyBirthdayChecker] = useState<Config>()
    const [enableDailyGarbageChecker, setEnableDailyGarbageChecker] = useState<Config>()
    const [silentMode, setSilentMode] = useState<Config>()
    const [factAPI, setFactAPI] = useState<Config>()
    const [totalUsage, setTotalUsage] = useState(0)
    const [apiKeyInformation, setApiKeyInformation] = useState<ApiAuthentication>()

    const _expiresAt = localStorage.getItem('expiresAt')
    const expiresAt = _expiresAt ? moment(_expiresAt).format('DD.MM.YYYY') : '-'

    const context = useContext(Context)
    const request = useContext(Api)
    const {getSettings, setSetting, getTranscriptionUsage, getApiKeyInformation} = request!

    const {setContentLoading} = context!

    const handleSettingChange = async (newValue: boolean | string, setting: Config) => {
        setContentLoading(true)
        const newSetting: Config = {
            id: setting.id,
            apiKeyID: setting.apiKeyID,
            setting_key: setting.setting_key,
            setting_value: newValue,
            created_at: setting.created_at,
            updated_at: setting.updated_at
        }
        setSetting(newSetting).then(resp => {
            if (resp.type === 'settings') {

                const calculateTimeRemaining = () => {
                    const nextQuarter = moment().startOf('minute')

                    if (moment().minutes() % 15 === 0 && moment().seconds() === 0) {
                        nextQuarter.add(15, 'minutes')
                    } else {
                        nextQuarter.minutes(Math.ceil(moment().minutes() / 15) * 15).seconds(0)
                    }

                    const duration = moment.duration(nextQuarter.diff(moment()))
                    let minutes = Math.floor(duration.asMinutes())
                    let seconds = duration.seconds()

                    if(minutes<0){
                        minutes = 14
                    }
                    if(seconds < 0){
                        seconds = seconds* (-1)
                    }
                    return(`${minutes < 10? '0'+minutes: minutes}:${seconds < 10? '0'+seconds: seconds}`)
                }

                toast(`Einstellungen werden in ${calculateTimeRemaining()} min vom Bot übernommen.`)
                fetchSettings()
            } else {
                setTimeout(() => setContentLoading(false), 600)
            }
        })
    }
    const truncateApiKey = (key: string) => {
        return key.length > 6 ? `${key.substring(0, 6)}...` : key
    }
    const fetchSettings = () => {
        getSettings().then((resp) => {
            setSettings(resp.message)
            setTimeout(() => setContentLoading(false), 600)
        })
    }
    useEffect(() => {
        fetchSettings()
        getTranscriptionUsage().then(usage =>{
            console.log(usage)
            setTotalUsage(parseFloat(usage.message.totalUsage.toFixed(2)))
        })
        getApiKeyInformation().then(info=>{
            setApiKeyInformation(info.message)
        })
    }, [])
    useEffect(() => {
        settings?.map(setting => {
            switch (setting.setting_key) {
                case SettingKey.personalizedBotAnswer:
                    setPersonalizedBotAnswer(setting)
                    break
                case SettingKey.transcribeWithBotMessage:
                    setTranscribeWithBotMessage(setting)
                    break
                case SettingKey.transcribeGroupMessages:
                    setTranscribeGroupMessages(setting)
                    break
                case SettingKey.enableDailyBirthdayChecker:
                    setEnableDailyBirthdayChecker(setting)
                    break
                case SettingKey.enableDailyGarbageChecker:
                    setEnableDailyGarbageChecker(setting)
                    break
                case SettingKey.silentMode:
                    setSilentMode(setting)
                    break
                case SettingKey.enableFactAPI:
                    setFactAPI(setting)
                    break
                case SettingKey.transcribeAudios:
                    setTranscribeAudios(setting)
                    break
                default:

            }
        })
    }, [settings])
    return (<>
        <Modal showModal={showPersonalizedBotMessageModal} setShowModal={setShowPersonalizedBotMessageModal}
               bgColor={'#ECE5DD'} content={<PersonalizedBotMessageModal personalizedBotAnswer={personalizedBotAnswer}
                                                                         setPersonalizedBotAnswer={setPersonalizedBotAnswer}
                                                                         handleSettingChange={handleSettingChange}/>}/>
        <HeadNavigation path={[{path: "/", pathName: "Startseite"}, {path: null, pathName: "Einstellungen"}]}/>
        <div className="grid">
            <div className="grid-item2-container">
                <div className="grid-item1">
                    <div className="grid-item2-container employee-card border">
                        <div className="card-header-logo">
                            {randomSVGPicture({height: 60, width: 60})}
                        </div>
                        <div className="employee-card-header">
                            {expiresAt === '-' || moment(expiresAt).isAfter(moment()) ? (
                                <div className="employee-active-status badge">Bot Aktiv bis {expiresAt}</div>
                            ) : (
                                <div className="employee-inactive-status badge">Bot Aktiv bis {expiresAt}</div>
                            )}

                            <div className=""></div>
                        </div>
                        <div className="employee-card-content">
                            <div className="employee-name fborder">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">WhatsApp Bot</div>
                                    <div className="col-12 d-flex justify-content-center"><p className={"fs-7"}>nächste
                                        Aktualisierung</p></div>
                                    <div className="col-12 d-flex justify-content-center"><h4><QuarterHourTimer/></h4>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        Transkribierte Sekunden: {totalUsage} / {apiKeyInformation?.tier.transcriptionLimit? apiKeyInformation.tier.transcriptionLimit: <Infinity className={"mt--1"} size={20}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-item2-container">
                <div className="content-name">Einstellungen</div>
                <div className="border section-content">
                    <div className="flex-row-container border-bottom section-id">
                        <div className={"flex-item1"}>API Key</div>
                        <div className={"flex-item2 test"}>{truncateApiKey(apiKey)}</div>
                    </div>

                    <div className="flex-row-container section-id">
                        <div className={"flex-item1"}>Aktiv bis</div>
                        <div className={"flex-item2"}>{expiresAt}</div>
                    </div>
                </div>
                <div className="section-header">Allgemein</div>
                <div className="border section-content">
                    {silentMode ? <div className={`flex-row-container pt-1`}>
                        <div className={"flex-item1"}>
                            <div className="row">
                                <div
                                    className="col-12 fw-bold">{description(silentMode?.setting_key).name}</div>
                                <div
                                    className="col-12">{description(silentMode?.setting_key).description}</div>
                            </div>
                        </div>
                        <div className={"flex-item2-toggle mt-2"}>
                            <CSwitch checked={!!silentMode.setting_value} onChange={handleSettingChange}
                                     setting={silentMode}/>
                        </div>
                    </div> : null}
                </div>
                <div className="section-header">Transkription</div>
                <div className="border section-content">

                    {transcribeAudios ? <div
                        className={`flex-row-container ${transcribeAudios.setting_value ? 'border-bottom' : null} pt-1`}>
                        <div className={"flex-item1"}>
                            <div className="row">
                                <div
                                    className="col-12 fw-bold">{description(transcribeAudios?.setting_key).name}</div>
                                <div
                                    className="col-12">{description(transcribeAudios?.setting_key).description}</div>
                            </div>
                        </div>
                        <div className={"flex-item2-toggle mt-2"}>
                            <CSwitch checked={!!transcribeAudios.setting_value} onChange={handleSettingChange}
                                     setting={transcribeAudios}/>
                        </div>
                    </div> : null}
                    {transcribeWithBotMessage && transcribeAudios?.setting_value ?
                        <div className={`flex-row-container border-bottom pt-1`}>
                            <div className={"flex-item1"}>
                                <div className="row">
                                    <div
                                        className="col-12 fw-bold">{description(transcribeWithBotMessage?.setting_key).name}</div>
                                    <div
                                        className="col-12">{description(transcribeWithBotMessage?.setting_key).description}</div>
                                </div>
                            </div>
                            <div className={"flex-item2-toggle mt-2"}>
                                <CSwitch checked={!!transcribeWithBotMessage.setting_value}
                                         onChange={handleSettingChange}
                                         setting={transcribeWithBotMessage}/>
                            </div>
                        </div> : null}
                    {transcribeWithBotMessage && transcribeAudios?.setting_value && personalizedBotAnswer ? <div
                        className={`flex-row-container border-bottom ps-4 ${transcribeWithBotMessage.setting_value ? 'expand' : 'collapse'}`}>
                        <div className={""}>
                            <div className="col-12 fw-bold">{description(personalizedBotAnswer.setting_key).name}</div>
                            <div className="col-12">{description(personalizedBotAnswer.setting_key).description}
                            </div>
                        </div>
                        <div className={"d-flex align-self-center mx-2"}>"{personalizedBotAnswer.setting_value}"</div>
                        <button
                            className={"align-self-center btn btn-gear flex-item2-toggle justify-content-center"}
                            onClick={() => setShowPersonalizedBotMessageModal(true)}><Gear size={20}/>
                        </button>
                    </div> : null}
                    {transcribeGroupMessages && transcribeAudios?.setting_value ? <div className={`flex-row-container border-bottom`}>
                        <div className={"flex-item1"}>
                            <div className="row">
                                <div
                                    className="col-12 fw-bold">{description(transcribeGroupMessages.setting_key).name}</div>
                                <div
                                    className="col-12">{description(transcribeGroupMessages.setting_key).description}</div>
                            </div>
                        </div>
                        <div className={"flex-item2-toggle mt-2"}>
                            <CSwitch checked={!!transcribeGroupMessages.setting_value} onChange={handleSettingChange}
                                     setting={transcribeGroupMessages}/>
                        </div>
                    </div> : null}
                    {transcribeAudios?.setting_value ? <div className={`flex-row-container`}>
                        <div className={"flex-item1"}>
                            <div className="row">
                                <div
                                    className="col-12 fw-bold">Transkribiere versteckt
                                </div>
                                <div
                                    className="col-12">Es besteht die Möglichkeit die Transkription in privaten Chats zu
                                    verstecken, sodass der Chatpartner diese nicht sieht. <br/>Dazu musst du unter <a
                                        href="personen"
                                        className={'fsf-6 link-primary text-decoration-underline'}>Personen</a> die
                                    entsprechende Person hinzufügen und kannst es dort einstellen.
                                </div>
                            </div>
                        </div>
                    </div>: null}
                </div>
                <div className="section-header">API's</div>
                <div className="border section-content">
                    {factAPI ? <div className={`flex-row-container pt-1`}>
                        <div className={"flex-item1"}>
                            <div className="row">
                                <div
                                    className="col-12 fw-bold">{description(factAPI.setting_key).name}</div>
                                <div
                                    className="col-12">{description(factAPI.setting_key).description}</div>
                            </div>
                        </div>
                        <div className={"flex-item2-toggle mt-2"}>
                            <CSwitch checked={!!factAPI.setting_value} onChange={handleSettingChange}
                                     setting={factAPI}/>
                        </div>
                    </div> : null}
                </div>
                <div className="section-header">Grüße & Erinnerungen</div>
                <div className="border section-content">
                    {enableDailyBirthdayChecker ? <div className={`flex-row-container border-bottom pt-1`}>
                        <div className={"flex-item1"}>
                            <div className="row">
                                <div
                                    className="col-12 fw-bold">{description(enableDailyBirthdayChecker?.setting_key).name}</div>
                                <div
                                    className="col-12">{description(enableDailyBirthdayChecker?.setting_key).description}</div>
                            </div>
                        </div>
                        <div className={"flex-item2-toggle mt-2"}>
                            <CSwitch checked={!!enableDailyBirthdayChecker.setting_value} onChange={handleSettingChange}
                                     setting={enableDailyBirthdayChecker}/>
                        </div>
                    </div> : null}
                    {enableDailyGarbageChecker ? <div className={`flex-row-container`}>
                        <div className={"flex-item1"}>
                            <div className="row">
                                <div
                                    className="col-12 fw-bold">{description(enableDailyGarbageChecker.setting_key).name}</div>
                                <div
                                    className="col-12">{description(enableDailyGarbageChecker.setting_key).description}</div>
                            </div>
                        </div>
                        <div className={"flex-item2-toggle mt-2"}>
                            <CSwitch checked={!!enableDailyGarbageChecker.setting_value} onChange={handleSettingChange}
                                     setting={enableDailyGarbageChecker} disabled/>
                        </div>
                    </div> : null}
                </div>
            </div>
        </div>
    </>)
}