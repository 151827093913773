import React, {useContext, useEffect, useState} from "react";
import './TierListView.css'
import HeadNavigation from "../../Components/HeadNavigation/HeadNavigation";
import {TierCard} from "../../Components/TierCard/TierCard";
import {AccountTier, Api, ApiAuthentication} from "../../RequestsProvider/RequestProvider";

export const TierListView = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [tierList, setTierList] = useState<AccountTier[]>()
    const [apiKeyInformation, setApiKeyInformation] = useState<ApiAuthentication>()

    const request = useContext(Api)
    const {getTierList, getApiKeyInformation} = request!

    useEffect(() => {
        getTierList().then(tl => {
            setTierList(tl.message)
            setIsLoading(false)
        })
        getApiKeyInformation().then(aki=>setApiKeyInformation(aki.message))
    }, [])
    return (
        <>
            <HeadNavigation path={[{path: "/", pathName: "Startseite"}, {path: null, pathName: "Account Stufen"}]}/>
            <div className="container">
                <div className="row mt-4">
                    <h1 className="text-center">Pick the best plan for you</h1>
                    {tierList && apiKeyInformation ? <>
                        <div className="col-12 col-lg-4">
                            <TierCard accountTier={tierList[0]} apiKeyInformation={apiKeyInformation}/>
                        </div>
                        <div className="col-12 col-lg-4">
                            <TierCard accountTier={tierList[2]} apiKeyInformation={apiKeyInformation}/>
                        </div>
                        <div className="col-12 col-lg-4">
                            <TierCard accountTier={tierList[1]} apiKeyInformation={apiKeyInformation}/>
                        </div>
                    </> : null}
                </div>
            </div>
        </>
    )
}